import useRouterHook from '@hook/useRouterHook';

interface UseProductListReturnType {
  onClickProduct: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
}
const useProductList = (): UseProductListReturnType => {
  const { push } = useRouterHook();

  const onClickProduct = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const { seq, url } = (e.target as HTMLButtonElement | HTMLAnchorElement)
      .dataset;

    if (seq) {
      e.preventDefault();
      push(`/product/detail/naver/:${seq}`);
    }
  };

  return { onClickProduct };
};

export default useProductList;
