/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactElement } from 'react';

import useEmblaCarousel from 'embla-carousel-react';

import { PLATFORM_TYPE } from '@common/const';
import { css } from '@emotion/react';
import { ProductsType, ProductItemType } from '@type/web/product';

import Button from '@components/common/Button/Button';
import ProductItem from '@components/common/ProductItem';
import SkeletonProductListTypeSwiper from '@components/common/Skeleton/Product/SkeletonProductListTypeSwiper';

interface ProductListTypeSwiperProps {
  products: Partial<ProductsType>[];
  productType?: keyof ProductItemType;
  name: string;
  onClickProduct: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  className?: string;
}
const ProductListTypeSwiper = ({
  products,
  productType,
  name,
  onClickProduct,
  className,
}: ProductListTypeSwiperProps): ReactElement => {
  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    loop: false,
    containScroll: 'trimSnaps',
  });

  /** 스켈레톤 */
  if (!products.length)
    return (
      <SkeletonProductListTypeSwiper
        css={wrap}
        className={className}
        emblaRef={emblaRef}
        productType={productType}
      />
    );

  return (
    <div css={wrap} className={className}>
      <div className="viewport" ref={emblaRef}>
        <div className="inner">
          {products.map((product: Partial<ProductsType>) => {
            const { seq, articleSeq, articleUrl, platformType } = product;
            const isCafeProduct = platformType === PLATFORM_TYPE.CAFE;
            const detailUrl = `/product/${
              isCafeProduct ? `detail/naver/${articleSeq}` : seq
            }`;

            const detailAsUrl = isCafeProduct
              ? `/product/detail/naver/${articleSeq}`
              : undefined;

            return (
              <Button
                key={`${name}_${seq || articleSeq}`}
                type="link"
                padding="0"
                to={detailUrl}
                as={detailAsUrl}
                data-seq={isCafeProduct ? articleSeq : ''}
                data-url={articleUrl}
                onClick={onClickProduct}
              >
                <ProductItem
                  type={productType || 'GALLERY'}
                  product={product}
                />
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const wrap = css`
  padding: 20px 0;
  .inner {
    display: flex;
    gap: 0 16px;
    a,
    & > div {
      display: block;
      min-width: 152px;
    }
  }
`;

export default React.memo(ProductListTypeSwiper);
