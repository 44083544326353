import React, { ReactElement, useEffect } from 'react';
import { useQuery } from 'react-query';

import { PLATFORM_TYPE } from '@common/const';
import { interfaceOpenAppProductDetail } from '@common/interface';
import { isAppDevice } from '@common/webview';
import { css } from '@emotion/react';
import { ProductItemType, ProductsType } from '@type/web/product';

import useRouterHook from '@lib/hook/useRouterHook';

import { getSearchPriceMainInfo } from '@api/standardPrice.ts/search';

import Button from '@components/common/Button/Button';
import ProductItem from '@components/common/ProductItem';

interface ProductListItemProps {
  name?: string;
  product: Partial<ProductsType> | undefined;
  productType?: keyof ProductItemType;
  transactionId?: number | null;
  priority?: boolean;
}

function isDefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined';
}

const ProductListItem = (props: ProductListItemProps): ReactElement => {
  const { name, product, productType, priority } = props;

  const {
    queryString: { priceKeyword },
  } = useRouterHook();
  const { data: mainData } = useQuery(
    ['main', priceKeyword],
    () => getSearchPriceMainInfo(priceKeyword),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!priceKeyword,
    },
  );

  /** 스켈레톤 */
  if (!product)
    return (
      <ProductItem
        type={productType || 'LIST'}
        product={product}
        priority={priority}
      />
    );

  const {
    seq,
    articleSeq,
    articleUrl,
    platformType,
    productPositionNo,
    title,
  } = product;
  const isCafeProduct = platformType === PLATFORM_TYPE.CAFE;
  const detailUrl = `/product/${
    isCafeProduct
      ? `detail/naver/${articleSeq}?seq=${articleSeq}&url=${articleUrl}`
      : seq
  }`;

  const detailAsUrl = isCafeProduct
    ? `/product/detail/naver/${articleSeq}`
    : undefined;

  const openAppProductDetail = () => {
    if (
      isAppDevice() &&
      mainData &&
      isDefined(articleSeq) &&
      isDefined(productPositionNo) &&
      isDefined(articleUrl) &&
      isDefined(title) &&
      isDefined(mainData?.data.transactionId)
    ) {
      const data = {
        abType: `${mainData.data.abTestType}`,
        productSeq: isCafeProduct ? 0 : articleSeq,
        productPos: productPositionNo ?? 0,
        transactionID: `${mainData.data.transactionId}`,
        cafeUrl: articleUrl ?? '',
        cafeSeq: articleSeq ?? 0,
        cafeTitle: title ?? '',
      };

      interfaceOpenAppProductDetail(data);
    }
  };

  return (
    <Button
      key={`${name}_${seq || articleSeq}`}
      type="link"
      padding="0"
      to={isAppDevice() ? '' : detailUrl}
      as={detailAsUrl}
      data-seq={isCafeProduct ? articleSeq : ''}
      data-url={articleUrl}
      onClick={openAppProductDetail}
      styleProps={css`
        text-align: start;
      `}
    >
      <ProductItem
        type={productType || 'LIST'}
        product={product}
        priority={priority}
      />
    </Button>
  );
};

export default React.memo(ProductListItem);
