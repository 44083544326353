import ProductItem from '@components/common/ProductItem';

export default function SkeletonProductListTypeSwiper({
  css,
  className,
  emblaRef,
  productType,
}: any) {
  return (
    <div css={css} className={className}>
      <div className="viewport" ref={emblaRef}>
        <div className="inner">
          {Array(5)
            .fill(null)
            .map((v, i) => (
              <ProductItem
                key={i}
                type={productType || 'GALLERY'}
                product={undefined}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
