import React, { ReactElement } from 'react';

import { Gutter } from 'antd/lib/grid/row';

import {
  ProductsType,
  ProductItemType,
  ProductListType,
} from '@type/web/product';
import { AdsInnerListItem } from '@type/web/search';

import useProductList from '../product/list/hook/useProductList';
import ProductListTypeGallery from './ProductListTypeGallery';
import ProductListTypeList from './ProductListTypeList';
import ProductListTypeSwiper from './ProductListTypeSwiper';

interface ProductListProps {
  type: keyof ProductListType;
  products: Partial<ProductsType>[];
  ads?: Array<AdsInnerListItem>;
  gutter?: Gutter | [Gutter, Gutter];
  flexItemsSize?: {
    MOBILE?: string;
    TABLET?: string;
    PC?: string;
  };
  productType?: keyof ProductItemType;
  name: string;
  className?: string;
  priority?: boolean;
  length?: number;
}

function ProductList({
  type,
  products,
  ads,
  gutter,
  flexItemsSize,
  productType,
  name,
  className,
  priority,
  length,
}: ProductListProps): ReactElement {
  const { onClickProduct } = useProductList();

  switch (type) {
    case 'LIST':
      return (
        <ProductListTypeList
          products={products}
          name={name}
          productType={productType}
          gutter={gutter}
          flexItemsSize={flexItemsSize}
          onClickProduct={onClickProduct}
          className={className}
          priority={priority}
          length={length}
        />
      );
    case 'SWIPER':
      return (
        <ProductListTypeSwiper
          products={products}
          name={name}
          productType={productType}
          onClickProduct={onClickProduct}
          className={className}
        />
      );

    default:
      return (
        <ProductListTypeGallery
          products={products}
          ads={ads || []}
          name={name}
          productType={productType}
          gutter={gutter}
          flexItemsSize={flexItemsSize}
          className={className}
          priority={priority}
          length={length}
        />
      );
  }
}

export default ProductList;
