import React, { ReactElement, useMemo } from 'react';

import { Row, Col } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

import { PLATFORM_TYPE } from '@common/const';
import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ProductsType, ProductItemType } from '@type/web/product';

import SkeletonProductListTypeList from '@components/common/Skeleton/Product/SkeletonProductListTypeList';
import ProductListItem from '@components/web/ProductList/ProductListTypeList/ProductListItem';

type flexItemsSizeType = {
  MOBILE?: string;
  TABLET?: string;
  PC?: string;
};

interface ProductListTypeListProps {
  products: Partial<ProductsType>[];
  productType?: keyof ProductItemType;
  gutter?: Gutter | [Gutter, Gutter];
  flexItemsSize?: flexItemsSizeType;
  name: string;
  onClickProduct: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  className?: string;
  priority?: boolean;
  length?: number;
}

const ProductListTypeList = ({
  products,
  productType,
  gutter = [
    { xs: 0, sm: 0, md: 0, lg: 0 },
    { xs: 16, sm: 16, md: 16, lg: 20 },
  ],
  flexItemsSize,
  name,
  className,
  priority,
  length = 20,
}: ProductListTypeListProps): JSX.Element => {
  /** 스켈레톤 */

  if (!products.length)
    return (
      <SkeletonProductListTypeList
        css={wrap}
        className={className}
        gutter={gutter}
        flexItemsSize={flexItemsSize}
        productType={productType}
        length={length}
      />
    );

  return (
    <Row css={wrap} gutter={gutter} className={className}>
      {products.map((product: Partial<ProductsType>) => {
        const { seq, articleSeq } = product;

        return (
          <StyledCol
            key={`${name}_${seq || articleSeq}`}
            flexItemsSize={flexItemsSize}
            className="col"
          >
            <ProductListItem
              name={name}
              productType={productType || 'LIST'}
              product={product}
              priority={priority}
            />
          </StyledCol>
        );
      })}
    </Row>
  );
};

const wrap = css`
  padding: 28px 0 8px;
  .col {
    > a {
      display: block;
    }
  }
`;

const StyledCol = styled(Col)(
  ({ flexItemsSize }: { flexItemsSize?: flexItemsSizeType }) => css`
    flex-basis: ${flexItemsSize?.PC || '33.33%'};

    @media (max-width: ${TABLET_WIDTH}) {
      flex-basis: ${flexItemsSize?.TABLET || '50%'};
    }

    @media (max-width: ${MOBILE_WIDTH}) {
      flex-basis: ${flexItemsSize?.MOBILE || '100%'};
    }
  `,
);

export default React.memo(ProductListTypeList);
