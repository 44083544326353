import { Row, Col } from 'antd';

import { MOBILE_WIDTH, TABLET_WIDTH } from '@common/constants/MEDIAS';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ProductListItem from '@components/web/ProductList/ProductListTypeList/ProductListItem';

type flexItemsSizeType = {
  MOBILE?: string;
  TABLET?: string;
  PC?: string;
};

const SkeletonProductListTypeList = ({
  css,
  className,
  gutter,
  flexItemsSize,
  productType,
  // StyledCol,
  length,
}: any): JSX.Element => {
  return (
    <Row css={css} className={className} gutter={gutter}>
      {Array(length)
        .fill(null)
        .map((v, i) => (
          <StyledCol key={i} flexItemsSize={flexItemsSize} className="col">
            <ProductListItem
              productType={productType || 'LIST'}
              product={undefined}
            />
          </StyledCol>
        ))}
    </Row>
  );
};

export default SkeletonProductListTypeList;

const StyledCol = styled(Col)(
  ({ flexItemsSize }: { flexItemsSize?: flexItemsSizeType }) => css`
    flex-basis: ${flexItemsSize?.PC || '33.33%'};

    @media (max-width: ${TABLET_WIDTH}) {
      flex-basis: ${flexItemsSize?.TABLET || '50%'};
    }

    @media (max-width: ${MOBILE_WIDTH}) {
      flex-basis: ${flexItemsSize?.MOBILE || '100%'};
    }
  `,
);
